import {Box, List, ListItem} from "@chakra-ui/react";
import React, {useContext} from "react";
import {MatchesContext, TournamentContext, UserContext} from "../../../contexts/main";
import TournamentBracket, {Player} from "../../../utility/classes/bracket/Bracket";

const Bracket = (props: {
    onMatchClick?: (match: Match) => void | {}
}) => {
    const {onMatchClick} = props
    const tournament = useContext(TournamentContext)
    const user = useContext(UserContext)
    const matches = useContext(MatchesContext)
    const matchIds = matches.map((match) => match.id)
    const winners = matches.filter((match) => {
        if (match.attributes.round) return match.attributes.round > 0
        else return false
    })
    const losers = matches.filter((match) => {
        if (match.attributes.round) return match.attributes.round < 0
        else return false
    })
    const players = tournament.relationships.participants.links.data as unknown as Participant[]
    const bracket = new TournamentBracket({
        size: players.length,
        players: players.map((player) => new Player({
            seed: player.attributes.seed,
            name: player.attributes.name,
            metaData: player.attributes
        })),
        layout: tournament.attributes.tournamentType
    })

    const createBranch = (node: any) => {
        const sideMargin = 50
        const verticalMargin = 10
        const matchId = node.matchId

        if (node.leftChild || node.rightChild) {
            return (
                <ListItem
                    display={"flex"}
                    flexDir={"row-reverse"}>
                    <Box
                        pos={"relative"}
                        ml={`${sideMargin}px`}
                        display={"flex"}
                        alignItems={"center"}
                        _after={{
                            position: "absolute",
                            content: '""',
                            width: `${sideMargin / 2}px`,
                            height: "2px",
                            left: 0,
                            top: "50%",
                            backgroundColor: "#fff",
                            transform: "translateX(-100%)"
                        }}>
                        <Box p={4} m={0} backgroundColor={"Beige"}>
                            <Box fontWeight={"bold"}>Match {matchId}</Box>
                            {node.leftPlayer && <Box>{node.leftPlayer.name}</Box>}
                            {node.rightPlayer && <Box>{node.rightPlayer.name}</Box>}
                        </Box>
                    </Box>

                    <List
                        display={"flex"}
                        flexDir={"column"}
                        justifyContent={"center"}>
                        {
                            node.leftChild &&
                              <ListItem
                                display={"flex"}
                                alignItems={"flex-start"}
                                justifyContent={"flex-end"}
                                my={`${verticalMargin}px`}
                                pos={"relative"}
                                _before={{
                                    content: '""',
                                    position: "absolute",
                                    backgroundColor: "#fff",
                                    right: 0,
                                    top: "50%",
                                    transform: "translateX(100%)",
                                    width: "25px",
                                    height: "2px",
                                }}
                                _after={{
                                    content: '""',
                                    position: "absolute",
                                    backgroundColor: "#fff",
                                    right: `-${sideMargin / 2}px`,
                                    height: "calc(50% + 22px)",
                                    width: "2px",
                                    top: "50%",
                                    display: node.rightChild ? "inherit" : "none",
                                }}>
                                  {createBranch(node.leftChild)}
                              </ListItem>
                        }
                        {
                            node.rightChild &&
                          <ListItem
                            display={"flex"}
                            alignItems={"flex-start"}
                            justifyContent={"flex-end"}
                            my={5}
                            pos={"relative"}
                            _before={{
                                content: '""',
                                position: "absolute",
                                backgroundColor: "#fff",
                                right: 0,
                                top: "50%",
                                transform: "translateX(100%)",
                                width: "25px",
                                height: "2px"
                            }}
                            _after={{
                                content: '""',
                                position: "absolute",
                                backgroundColor: "#fff",
                                right: `-${sideMargin / 2}px`,
                                height: "calc(50% + 22px)",
                                width: "2px",
                                top: "50%",
                                transform: "translateY(-100%)"
                            }}>
                              {createBranch(node.rightChild)}
                          </ListItem>
                        }
                    </List>
                </ListItem>
            )
        } else {
            return (
                <ListItem display={"flex"} dir={"row-reverse"}>
                    <Box p={4} m={0} backgroundColor={"Beige"}>
                        <Box fontWeight={"bold"}>Match {matchId}</Box>
                        <Box>{node.leftPlayer && node.leftPlayer.name}</Box>
                        <Box>{node.rightPlayer && node.rightPlayer.name}</Box>
                    </Box>
                </ListItem>
            )
        }
    }

    return (
        <>
            <List
                display={"flex"}
                minH={"600px"}
                justifyContent={"center"}>
                {createBranch(bracket.root)}
            </List>
        </>
    )
}

export default Bracket
