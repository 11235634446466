export class Player {
    seed: number | undefined = undefined
    name = ""
    record = [0, 0]
    metaData: { [key: string]: any }

    constructor(props: {
        seed: number
        name: string
        metaData?: any
    }) {
        const {seed, name, metaData} = props
        this.seed = seed
        this.name = name
        this.metaData = metaData
    }

    setSeed(seed: number) {
        this.seed = seed
    }

    setName(name: string) {
        this.name = name
    }

    setMetaData(data: { [key: string]: any }) {
        this.metaData = {...this.metaData, ...data}
    }
}

class Node {
    matchId: number | undefined = undefined
    leftPlayer
    rightPlayer
    static dummyPlayer1: Player = new Player({seed: 0, name: "Dummy Player"})
    static dummyPlayer2: Player = new Player({seed: 0, name: "Dummy Player"})
    public winner: Player | undefined = undefined
    leftChild: Node | undefined
    rightChild: Node | undefined
    parent
    losersMatch: Node | undefined
    round: number | undefined = undefined
    score = [0, 0]
    type: "winners" | "losers"
    metaData: { [key: string]: any }

    constructor(props: {
        matchId: number
        leftChild?: Node
        rightChild?: Node
        leftPlayer?: Player
        rightPlayer?: Player
        parent?: Node
        losersMatch?: Node
        round?: number
        type?: "winners" | "losers"
        metaData?: any
    }) {
        const {
            matchId,
            leftPlayer,
            rightPlayer,
            leftChild,
            rightChild,
            parent,
            losersMatch,
            round,
            type,
            metaData
        } = props
        this.matchId = matchId;
        this.leftPlayer = leftPlayer ? leftPlayer : Node.dummyPlayer1;
        this.rightPlayer = rightPlayer ? rightPlayer : Node.dummyPlayer2;
        leftChild && this.setLeftChild(leftChild)
        rightChild && this.setRightChild(rightChild)
        this.losersMatch = losersMatch
        this.parent = parent
        this.round = round
        this.type = type ? type : "winners"
        this.metaData = metaData

        if (this.leftChild) {
            Node.dummyPlayer1.setName(`winner of ${this.leftChild.matchId}`)
            this.leftChild.parent = this
        }
        if (this.rightChild) {
            Node.dummyPlayer1.setName(`winner of ${this.rightChild.matchId}`)
            this.rightChild.parent = this
        }
    }

    isLeftChild(): boolean {
        if (!this.parent) return false
        if (this.parent.leftChild) return this.matchId === this.parent.leftChild.matchId
        return false
    }

    isRightChild(): boolean {
        if (!this.parent) return false
        if (this.parent.rightChild) return this.matchId === this.parent.rightChild.matchId
        return false
    }

    isOnlyChild(): boolean {
        if (!this.parent) return false
        return this.parent.rightChild !== undefined
    }

    setChild(node: Node) {
        if (!node) return
        if (!this.leftChild) this.setLeftChild(node)
        else this.setRightChild(node)
        node.setParent(this)
    }

    setLeftChild(node: Node | undefined) {
        if (node) {
            this.leftChild = node
            node.setParent(this)
        }
    }

    setRightChild(node: Node | undefined) {
        if (node) {
            this.rightChild = node
            node.setParent(this)
        }
    }

    setLosersMatch(node: Node | undefined) {
        if (node) this.losersMatch = node
    }

    setLeftPlayer(player: Player) {
        this.leftPlayer = player;
    }

    setRightPlayer(player: Player) {
        this.rightPlayer = player;
    }

    setMatchId(id: number) {
        this.matchId = id
    }

    setParent(parent: Node) {
        if (parent) this.parent = parent
    }

    setRound(round: number) {
        if (!Number.isNaN(round)) this.round = round
    }

    setMetaData(data: any) {
        this.metaData = {...this.metaData, ...data}
    }

    setAttributes(attributes: {
        round?: number
        parent?: Node
        type?: "winners" | "losers"
    }) {
        const {
            round,
            parent,
            type,
        } = attributes

        round && this.setRound(round)
        if (parent) this.parent = parent
        if (type) this.type = type
    }

    updateScore(score: number, winner: Player) {
        if (!this.leftPlayer && !this.rightPlayer) return
        if (winner.seed === this.leftPlayer!.seed) this.score[0] = score
        else this.score[1] = score
    }

    advanceWinner(winner: Player) {
        if (!this.leftPlayer && !this.rightPlayer && !this.parent) return
        if (this.parent!.leftChild) if (this.isLeftChild() && !this.parent!.leftPlayer.seed) this.parent!.setLeftPlayer(winner)
        else if (this.parent!.rightChild) if (this.isRightChild() && !this.parent!.rightPlayer.seed) this.parent!.setRightPlayer(winner)
        this.winner = winner
    }
}

class TournamentBracket {
    size = 3
    root
    losersRoot?: Node
    numberOfByes = 0
    highestPowerOf2
    players
    seededMatches
    byes
    layout: TournamentType
    metaData?: { [key: string]: any }

    constructor(props: {
        size: number
        players?: Player[]
        layout: TournamentType
        metaData?: any
    }) {
        const {
            size,
            players,
            layout,
            metaData
        } = props
        this.size = size
        this.layout = layout
        this.root = new Node({
            matchId: size - 1,
            round: this.calculateRounds(),
            type: "winners"
        })
        this.highestPowerOf2 = this.findHighestPowerOf2()
        this.numberOfByes = this.highestPowerOf2 - size
        this.players = players ? players : this.generatePlayers()
        this.seededMatches = this.seedOrder()
        this.byes = this.seededMatches.filter((seeds) => !seeds[1])
        this.metaData = metaData
        this.generateBracketLayout(layout)
    }

    generateBracketLayout(layout: TournamentType) {
        if (layout === 'Double Elimination') {
            this.generateTree(this.root) // generate winners
            this.generateLosersBracket(this.root) //generate losers
        } else {
            this.generateTree(this.root)
        }
    }

    generateLosersBracket (root: Node = this.root) {
        const losersBracket = [...this.generateMatches(this.size, "losers")]
        const numberOfRounds = this.calculateRounds(this.size)
        let round = 1

        while (round <= numberOfRounds) {
            const currentRoundMatches = this.getNodesByRound(round, {type: "winners"})

            if (round === 1) {
                const byes = this.findHighestPowerOf2(currentRoundMatches.length) - currentRoundMatches.length
                const numberOfPlayers = (currentRoundMatches.length - byes)
                const numberOfMatches = numberOfPlayers / 2
                // for (let i = 0;i < numberOfMatches;i++) losersBracket[i].setRound(round)
            } else if (round > 1) {
                const previousRoundMatches = this.getNodesByRound(round - 1, {type: "losers"})
                const byesFromPreviousRound = this.findHighestPowerOf2(previousRoundMatches.length) - previousRoundMatches.length
                const numberOfPlayers = currentRoundMatches.length / 2
                const byes = this.findHighestPowerOf2(numberOfPlayers) - numberOfPlayers
                const numberOfMatches = (numberOfPlayers - byes) + (previousRoundMatches.length / 2) + byesFromPreviousRound
                // const offset = losersBracket.filter((match) => match.round < round && match.round).length

                if (!this.isPowerOf2(numberOfMatches)) {
                    const numberOfMatches = (previousRoundMatches.length / 2) + byesFromPreviousRound
                    // for (let i = offset; i < numberOfMatches + offset; i++) losersBracket[i].setRound(round)
                } else {
                    // for (let i = offset; i < numberOfMatches + offset; i++) losersBracket[i].setRound(round)
                }
            }

            round++
        }

        this.mapLosersMatches(losersBracket)
    }

    generateMatches (size: number = this.size, type: "winners" | "losers" = "winners") {
        const matches = []
        const offset = type === "losers" ? this.size - 1 : 0
        for (let i = 0; i < (size) - 2; i++) {
            matches[i] = new Node({
                matchId: i + offset + 1,
                type,
            })
        }
        return matches
    }

    generateTree(root: Node = this.root, size: number = this.size) {
        if (size < 2) return
        const numberOfRounds = this.calculateRounds(size)
        let roundsRemaining = numberOfRounds
        const matches = [...this.generateMatches(size)]

        while (roundsRemaining > 2) {
            const nodes = roundsRemaining === numberOfRounds ? [root] : this.getNodesByRound(roundsRemaining, {type: "winners"}).reverse()

            nodes.forEach((node) => {
                if (!matches.length) return
                const leftMatch = matches.splice(-2, 1)[0]!
                leftMatch.setRound(node.round! - 1)
                if (!node.leftChild) node.setLeftChild(leftMatch)
                else node.setRightChild(leftMatch)

                if (!matches.length) return
                const rightMatch = matches.splice(-1, 1)[0]!
                rightMatch.setRound(node.round! - 1)
                node.setRightChild(rightMatch)
            })

            roundsRemaining--
        }

        this.buildRound1Matches(matches)
    }

    buildRound1Matches(matches: Node[]) {
        const nextRoundNodes = this.getNodesByRound(2)
        let byes = 0, numberOfPlayers = this.size

        if (!this.isPowerOf2(this.size)) {
            byes = this.findHighestPowerOf2(this.size) - this.size
            numberOfPlayers = this.size - byes
        }

        const numberOfMatches = numberOfPlayers / 2

        matches.forEach((match) => match.setRound(1))
        for (let i = 0;i < Math.ceil(numberOfMatches + byes);i++) matches[i] = matches[i]

        const shuffledMatches = this.shuffleMatches(matches)
        const round1Matches = [] as unknown as any[][]
        while (shuffledMatches.length) round1Matches.push(shuffledMatches.splice(0, 2))

        nextRoundNodes.forEach((match, index) => {
            if (round1Matches[index][0]) match.setChild(round1Matches[index][0])
            if (round1Matches[index][1]) match.setChild(round1Matches[index][1])
        })
    }

    mapLosersMatches (losersBracket: Node[]) {
        const numberOfRounds = this.calculateRounds(this.size)
        const losersMatchesByRound = [] as Node[][]
        for (let currentRound = 1;currentRound < numberOfRounds + 1;currentRound++) {
            const currentRoundMatches = this.getNodesByRound(currentRound, {type: "winners"})
            const previousRoundMatches = losersMatchesByRound.filter((match) => match[0].round === currentRound - 1)
            const nextRoundMatches = this.getNodesByRound(currentRound + 1, {type: "winners"})

            console.log(`Round ${currentRound}`, previousRoundMatches,)

            if (!this.isPowerOf2(currentRoundMatches.length)) {
                const byesFromWinnersRound = this.findHighestPowerOf2(currentRoundMatches.length) - currentRoundMatches.length
                const byesFromPreviousLosersRound = this.findHighestPowerOf2(previousRoundMatches.length) - previousRoundMatches.length
                const numberOfPlayers = (currentRoundMatches.length - byesFromWinnersRound) + previousRoundMatches.length
                const numberOfMatches = (numberOfPlayers + byesFromPreviousLosersRound) / 2
                const matches = losersBracket.splice(0, numberOfMatches - 1)
                matches.forEach((match) => match.setRound(currentRound))
                losersMatchesByRound.push(matches)
            } else {
                const byesFromPreviousLosersRound = this.findHighestPowerOf2(previousRoundMatches.length) - previousRoundMatches.length
                const numberOfPlayers = currentRoundMatches.length + previousRoundMatches.length
                const numberOfMatches = (numberOfPlayers + byesFromPreviousLosersRound) / 2
                const matches = losersBracket.splice(0, numberOfMatches - 1)
                matches.forEach((match) => match.setRound(currentRound))
                losersMatchesByRound.push(matches)
            }
        }
    }

    getNodeById(id: number, node = this.root): Node | undefined {
        let found = undefined
        if (node.matchId === id) return node
        if (node.leftChild) found = this.getNodeById(id, node.leftChild)
        if (node.rightChild && !found) found = this.getNodeById(id, node.rightChild)
        return found
    }

    getNodesByRound(round: number, filters?: {
        node?: Node,
        type?: "winners" | "losers"
    }): Node[] {
        const {node = this.root, type = false} = filters ? filters : {}
        const nodes = [] as Node[]
        if (node.round === round) nodes.push(node)
        if (node.leftChild) nodes.push(...this.getNodesByRound(round, {node: node.leftChild}))
        if (node.rightChild) nodes.push(...this.getNodesByRound(round, {node: node.rightChild}))
        if (type) return nodes.filter((node) => node.type === type)
        return nodes
    }

    getOuterLeftChild(node = this.root) {
        while (node.leftChild !== undefined) node = node.leftChild
        return node
    }

    getOuterRightChild(node = this.root) {
        while (node.rightChild !== undefined) node = node.rightChild
        return node
    }

    setMetaData(data: any) {
        this.metaData = {...this.metaData, ...data}
    }

    deleteNodeById(id: number) {
        const node = this.getNodeById(id)
        if (node) {
            const parent = node.parent
            if (!parent) return
            if (parent.leftChild) {
                if (parent.leftChild.matchId === id) {
                    if (parent.rightChild) {
                        if (parent.rightChild.matchId === id) parent.setRightChild(undefined)
                        else {
                            // parent.rightChild.setMatchId(parent.leftChild.matchId)
                            parent.setLeftChild(parent.rightChild)
                            parent.setRightChild(undefined)
                        }
                    } else parent.setLeftChild(undefined)
                }
            }
        }
    }

    generatePlayers() {
        return new Array(this.size)
            .fill(0) // placeholder value
            .map((...args) => new Player({
                name: `Player ${args[1] + 1}`,
                seed: args[1] + 1,
            }))
    }

    seedOrder() {
        const seeds =
            new Array(this.size).fill(null)
                .map((seed, index, array) => [this.numberOfByes + index + 1, array.length - index])
                .slice(0, Math.ceil((this.size) / 2) - (this.numberOfByes / 2))

        for (let i = this.numberOfByes; i > 0; i--) seeds.unshift([i, 0])

        return seeds
    }

    shuffleMatches(matches: Node[]) {
        const rounds = this.calculateRounds()

        for (let i = 0; i < rounds; i++) {
            const shuffled = []
            const offset = Math.pow(2, i)
            while (matches.length) {
                shuffled.push(...matches.splice(0, offset))
                shuffled.push(...matches.splice(-offset))
            }
            matches = shuffled
        }

        return matches
    }

    calculateRounds(size: number = this.size) {
        let rounds = 1
        while (Math.pow(2,rounds) < size) rounds++
        return rounds
    }

    findHighestPowerOf2(threshold: number = this.size) {
        let i = 1
        while (threshold > Math.pow(2, i) && threshold !== Math.pow(2, i)) i++
        return Math.pow(2, i)
    }

    isPowerOf2(x: number) {
        return (Math.log2(x) % 1 === 0)
    }
}

export default TournamentBracket
